import React, { useCallback, useState } from 'react'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import generateTestId from 'utils/test-helpers'
import AlarmCountButton from './AlarmCountButton'
import Button from 'core/elements/button'
import ExternalLink from 'core/components/ExternalLink'
import SimpleLink from 'core/components/SimpleLink'
import clsx from 'clsx'
import OverviewAlarmsModal from './OverviewAlarmsModal'
import SelectableCard from 'core/components/SelectableCard'
import useReactRouter from 'use-react-router'
import { ClusterTypes } from 'app/plugins/infrastructure/components/clusters/model'
import { routes } from 'core/utils/routes'
import { getClusterDetailsPath } from 'app/plugins/infrastructure/components/clusters/helpers'
import useGrafanaLink from 'core/hooks/useGrafanaLink'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 450px))',
    gridGap: 32,
  },
  card: {
    background: theme.components.card.background,
    display: 'grid',
    gridGap: 16,
    borderRadius: 4,
  },
  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  alignCenter: {
    alignItems: 'center',
  },
  alarmCount: {
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'end',
    gap: 8,
  },
  alarmButtons: {
    marginTop: 16,
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'end',
    gap: 8,
  },
}))

export default function ClusterAlarmCards({ clusters }) {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const [open, setModalOpen] = useState(false)
  const [cluster, setCluster] = useState(null)
  const onClose = useCallback(() => {
    setModalOpen(false)
    setCluster(null)
  }, [setModalOpen])
  const viewMore = useCallback(
    (cluster) => {
      setCluster(cluster)
      setModalOpen(true)
    },
    [setCluster],
  )

  const handleClick = ({ uuid, clusterType }) => {
    history.push(getClusterDetailsPath({ clusterId: uuid, clusterType, params: { tab: 'alarms' } }))
  }

  // Get Grafana service URL from service labels
  const { grafanaLink } = useGrafanaLink(cluster?.usage?.grafanaLink, cluster?.uuid)

  return (
    <>
      <div className={classes.cards}>
        {clusters.map((cluster) => (
          <SelectableCard
            className={classes.card}
            key={cluster.uuid}
            id={cluster.uuid}
            onClick={() => handleClick(cluster)}
          >
            <div className={classes.spaceBetween}>
              <Text data-testid={generateTestId('cluster', 'name')} variant="subtitle2">
                {cluster.name}
              </Text>
              <div>
                <div className={classes.alarmCount}>
                  <Text variant="body2">Total Alarms</Text>
                  <Text variant="caption1">{cluster.numAlarms}</Text>
                </div>
                <div className={classes.alarmButtons}>
                  <AlarmCountButton count={cluster.fatalAlarms.length} type="fatal" />
                  <AlarmCountButton count={cluster.criticalAlarms.length} type="critical" />
                  <AlarmCountButton count={cluster.warningAlarms.length} type="warning" />
                </div>
              </div>
            </div>
            <div className={clsx(classes.spaceBetween, classes.alignCenter)}>
              <ExternalLink textVariant="caption1" url={grafanaLink}>
                <Button variant="secondary" rightIcon="arrow-up-right-from-square">
                  View Grafana
                </Button>
              </ExternalLink>
              <SimpleLink src="" onClick={() => viewMore(cluster)}>
                View more
              </SimpleLink>
            </div>
          </SelectableCard>
        ))}
      </div>
      {cluster && <OverviewAlarmsModal open={open} onClose={onClose} cluster={cluster} />}
    </>
  )
}
