import React, { useCallback } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteCustomResource } from '../../actions'
import { ICustomResourceDefinitionDetailsPageTabs } from '../../model'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  crdList: {
    maxHeight: 180,
    overflow: 'auto',
    border: `1px solid ${theme.components.table.border}`,
    borderRadius: 4,
    padding: '16px 32px',
    listStyleType: 'none',
  },
}))

function DeleteCustomResourceDialog({ rows, onClose }) {
  const { history, match } = useReactRouter()
  const { id, clusterId } = match.params
  const classes = useStyles()

  const { update: deleteFn, updating: deletingCustomResource } = useUpdateAction(
    deleteCustomResource,
  )

  const handleDelete = useCallback(async () => {
    const promises = rows.map((customResourceDefinition) => {
      deleteFn(customResourceDefinition)
    })

    await Promise.allSettled(promises)
    onClose(true)
    history.push(
      routes.clusterAdministration.customResourceDefinitions.details.path({
        clusterId,
        id,
        tab: ICustomResourceDefinitionDetailsPageTabs.CustomResources,
      }),
    )
  }, [rows])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Are you sure?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingCustomResource}>
            Cancel
          </Button>
          <Button onClick={handleDelete} loading={deletingCustomResource}>
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body2">
        {`Following ${rows.length > 1 ? rows.length + ' CRs' : 'CR'} will be deleted`}
      </Text>
      <ul className={classes.crdList}>
        {rows?.map((row) => (
          <li key={row.id}>
            <Text variant="caption1">{row.name}</Text>
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default DeleteCustomResourceDialog
