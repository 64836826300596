import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { findCluster } from 'k8s/util/helpers'
import {
  ICustomResourceDefinitionSelector,
  CustomResourceDefinitionVersion,
  ICustomResourcesSelector,
} from './model'
import { last } from 'ramda'
import { durationBetweenDates } from 'utils/misc'

export const customResourceDefinitionsSelector = createSharedSelector(
  getDataSelector<DataKeys.CustomResourceDefinitions>(
    DataKeys.CustomResourceDefinitions,
    ['clusterId'],
    ['clusterId'],
  ),
  allClustersSelector,
  (rawCustomResourceDefinitions, allClusters): ICustomResourceDefinitionSelector[] => {
    return rawCustomResourceDefinitions.map((rawCustomResourceDefinition) => {
      const { clusterId, metadata, spec } = rawCustomResourceDefinition
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)

      return {
        ...rawCustomResourceDefinition,
        clusterName,
        clusterType,
        group: spec?.group,
        scope: spec?.scope,
        latestVersion: last(spec?.versions as Array<CustomResourceDefinitionVersion>)?.name,
        creationTimestamp: metadata?.creationTimestamp,
        annotations: metadata?.annotations,
        labels: metadata?.labels,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
      }
    })
  },
)

export const createGroupOptionsSelector = createSharedSelector(
  getDataSelector<DataKeys.CustomResourceDefinitions>(
    DataKeys.CustomResourceDefinitions,
    ['clusterId'],
    ['clusterId'],
  ),
  allClustersSelector,
  (rawCustomResourceDefinitions): Array<{ label: string; value: string }> => {
    const distinctGroups = Array.from(
      new Set(rawCustomResourceDefinitions?.flatMap(({ spec }) => spec.group)),
    )

    const groupByOptions: Array<{ label: string; value: string }> = distinctGroups.map(
      (group: string) => {
        return {
          label: group,
          value: group,
        }
      },
    )

    return groupByOptions
  },
)

export const customResourcesSelector = createSharedSelector(
  getDataSelector<DataKeys.CustomResources>(
    DataKeys.CustomResources,
    ['clusterId', 'group', 'version', 'pluralName'],
    ['clusterId'],
  ),
  allClustersSelector,
  (rawCustomResources, allClusters): ICustomResourcesSelector[] => {
    return rawCustomResources.map((rawCustomResourceDefinition) => {
      const {
        clusterId,
        metadata,
        status,
        group,
        version,
        pluralName,
        id,
      } = rawCustomResourceDefinition
      const { name: clusterName, clusterType: clusterType } = findCluster(allClusters, clusterId)

      return {
        ...rawCustomResourceDefinition,
        clusterName,
        clusterType,
        group,
        version,
        pluralName,
        id,
        phase: status?.phase,
        namespace: metadata?.namespace,
        creationTimestamp: metadata?.creationTimestamp,
        labels: metadata?.labels,
        age: durationBetweenDates({ labels: ['d'] })(metadata?.creationTimestamp),
      }
    })
  },
)
