import React, { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { routes } from 'core/utils/routes'
import { deleteCustomResourceDefinition, listCustomResources } from './actions'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

const useStyles = makeStyles<Theme>((theme) => ({
  crdList: {
    maxHeight: 180,
    overflow: 'auto',
    border: `1px solid ${theme.components.table.border}`,
    borderRadius: 4,
    padding: '16px 32px',
    listStyleType: 'none',
  },
}))

function DeleteCustomResourceDefinitionDialog({ rows, onClose }) {
  const { history } = useReactRouter()
  const classes = useStyles()
  const { update: deleteFn, updating: deletingCustomResourceDefinition } = useUpdateAction(
    deleteCustomResourceDefinition,
  )

  const handleDelete = useCallback(async () => {
    const promises = rows.map((customResourceDefinition) => {
      deleteFn(customResourceDefinition)
    })
    await Promise.allSettled(promises)
    onClose(true)
    history.push(routes.clusterAdministration.customResourceDefinitions.list.path())
  }, [rows])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={`Are you sure?`}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} disabled={deletingCustomResourceDefinition}>
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            // disabled={loadingCustomResource}
            loading={deletingCustomResourceDefinition}
          >
            Delete
          </Button>
        </>
      }
    >
      <Text variant="body1">{`The selected ${rows?.length > 1 ? rows?.length : ''} CRD${
        rows?.length > 1 ? 's' : ''
      } may have associated CRs`}</Text>
      <ul className={classes.crdList}>
        {rows?.map((row) => (
          <li key={row.id}>
            <Text variant="caption1">{row.name}</Text>
          </li>
        ))}
      </ul>
    </Modal>
  )
}

export default DeleteCustomResourceDefinitionDialog
