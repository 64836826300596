import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Progress from 'core/components/progress/Progress'
import { renderAge } from 'k8s/components/common/entity/helpers'
import SummaryCard from 'k8s/components/common/entity/summary-card'
import { ICustomResourcesSelector } from 'k8s/components/cluster-administration/custom-resource-definitions/model'
import InfoCard from 'k8s/components/common/entity/info-card'
import { getFieldsForCard } from 'core/components/InfoPanel'
import LabelsAndAnnotationsSection from 'k8s/components/common/entity/labels-and-annotations/LabelsAndAnnotationsSection'
import Grid from 'core/elements/grid/Grid'
import Card from 'core/elements/card/Card'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'

const statusConditionsColumns = [
  { key: 'type', label: 'Type' },
  { key: 'status', label: 'Status' },
  {
    key: 'lastTransitionTime',
    label: 'Last Transition Time',
    CellComponent: DateAndTime,
  },
  { key: 'reason', label: 'Reason' },
  { key: 'message', label: 'Message' },
]

const summaryRows: { key: keyof ICustomResourcesSelector; label: string }[] = [
  { key: 'clusterName', label: 'Cluster' },
  { key: 'namespace', label: 'Namespace' },
  { key: 'kind', label: 'Kind' },
]

const CustomResourceOverview = ({
  customResource,
  loading,
}: {
  customResource: ICustomResourcesSelector
  loading: boolean
}) => {
  const classes = useStyles()
  if (customResource) customResource.namespace = customResource?.namespace ?? 'default'

  const metadataFields = [
    {
      id: 'age',
      title: 'Created',
      required: true,
      render: renderAge,
    },
  ]

  const metadata = useMemo(() => {
    return getFieldsForCard(metadataFields, customResource)
  }, [customResource])

  return (
    <Progress loading={loading} renderContentOnMount={false}>
      <div className={classes.container}>
        <div className={classes.column}>
          <SummaryCard<ICustomResourcesSelector>
            title={customResource?.name}
            subtitle="Custom Resource"
            rows={summaryRows}
            data={customResource}
          />
          <InfoCard
            items={metadata}
            title="Properties"
            footer={
              <div className={classes.footer}>
                <LabelsAndAnnotationsSection entity={customResource} resourceType="configMap" />
              </div>
            }
          />
        </div>
        <div className={classes.column}>
          <Card title={'Status Conditions'} withCustomBody>
            <Grid
              uniqueIdentifier="type"
              data={customResource?.status?.conditions ?? []}
              columns={statusConditionsColumns}
              disableToolbar
            />
          </Card>
        </div>
      </div>
    </Progress>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '633px 1fr',
    marginTop: '16px',
    gridGap: '24px',
  },
  column: {
    display: 'grid',
    gridGap: '24px',
    gridAutoFlow: 'row',
    gridAutosummaryRows: 'max-content',
  },
  footer: {
    margin: theme.spacing(1),
  },
  copyClipboard: {
    '& .copy': {
      color: theme.components.typography.passive,
    },
  },
}))

export default CustomResourceOverview
