import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Button from 'core/elements/button'
import { notificationActions, NotificationType } from 'core/notifications/notificationReducers'
import Theme from 'core/themes/model'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import jsYaml from 'js-yaml'
import { customValidator, requiredValidator, yamlValidator } from 'core/utils/fieldValidators'
import moize from 'moize'
import SubmitButton from 'core/components/SubmitButton'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>((theme) => ({
  yamlPage: {
    marginTop: theme.spacing(2),
  },
  code: {
    '& .MuiFormControl-root.validatedFormInput': {
      width: '100%',
    },
  },
  codeMirror: {
    marginTop: '0 !important',
    '& .CodeMirror': {
      height: 'calc(100vh - 300px)',
      minHeight: 300,
      maxHeight: 'calc(100vh - 300px)',
    },
  },
  yamlContainer: {
    position: 'relative',

    '& .formActions': {
      display: 'grid',
      gap: theme.spacing(2),
      gridTemplateColumns: '86px 86px',
      alignItems: 'start',
      margin: 0,
    },
    '& .formActions > button': {
      marginTop: theme.spacing(),
    },
    '& .form-field-card-requirementsTitle': {
      minHeight: '40px',
      alignItems: 'center',
      marginBottom: '8px',
    },
  },
  errorMessage: {
    margin: 12,
  },
}))

export const moizedYamlLoad = moize(jsYaml.load, {
  maxSize: 10,
})

const defaultCodeMirrorValidations = [
  requiredValidator,
  yamlValidator,
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.name
  }, 'metadata.name must be set'),
  customValidator((yaml) => {
    const body = moizedYamlLoad(yaml)
    return !!body?.metadata?.namespace
  }, 'metadata.namespace must be set'),
]

const EntityYamlPage = ({
  entityType,
  entityName = '',
  getYamlFn,
  handleUpdate,
  yamlValidations = defaultCodeMirrorValidations,
  loading: loadingExternal = false,
  error = undefined,
  className = undefined,
}) => {
  const classes = useStyles()
  const [yaml, setYaml] = useState(null)
  const [loading, setLoading] = useState(null)
  const dispatch = useDispatch()
  const formFieldSetter = useRef(null)
  const fieldSetter = (setField) => {
    formFieldSetter.current = { setField }
  }
  const errorMessage = error?.message || error

  useEffect(() => {
    const getYaml = async () => {
      try {
        setLoading(true)
        const response = await getYamlFn()
        if (!response) {
          setLoading(false)
          return
        }
        const yaml = jsYaml.dump(response) || null
        formFieldSetter.current.setField('yaml')(yaml)
        setYaml(yaml)
      } catch (err) {
        dispatch(
          notificationActions.registerNotification({
            title: `${entityType} YAML Error`,
            message: err?.message,
            data: err,
            type: NotificationType.error,
          }),
        )
      } finally {
        setLoading(false)
      }
    }
    getYaml()
  }, [getYamlFn])

  const handleSubmit = useCallback(
    ({ yaml: updatedYaml }) => {
      if (updatedYaml === yaml) return
      handleUpdate(updatedYaml)
    },
    [yaml, handleUpdate],
  )

  const reset = useCallback(() => {
    formFieldSetter.current.setField('yaml')(yaml)
  }, [yaml])

  return (
    <div className={classes.yamlPage}>
      <DocumentMeta title={`${entityType} YAML`} bodyClasses={['form-view']} />
      <ValidatedForm
        elevated={false}
        classes={{ root: classes.yamlContainer }}
        onSubmit={handleSubmit}
        initialValues={yaml}
        fieldSetter={fieldSetter}
        formActions={
          <>
            <Button type="button" variant="secondary" size="large" onClick={reset}>
              Reset
            </Button>
            <SubmitButton>Save</SubmitButton>
          </>
        }
      >
        {({ setFieldValue, values }) => (
          <>
            <div className={clsx(classes.code, className)}>
              <CodeMirror
                label={`${entityType} YAML`}
                variant="light"
                className={classes.codeMirror}
                id="yaml"
                value={values.yaml}
                onChange={setFieldValue('yaml')}
                validations={yamlValidations}
                loading={loadingExternal || loading}
                showSearchBar
                showCopyButton
                showDownloadButton
                downloadFileName={`${entityName}.yaml`}
              />
            </div>
            {errorMessage && (
              <ErrorMessage className={classes.errorMessage}>{errorMessage}</ErrorMessage>
            )}
          </>
        )}
      </ValidatedForm>
    </div>
  )
}

export default EntityYamlPage
