import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import ListAction from 'core/actions/ListAction'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'

const { qbert } = ApiClient.getInstance()

const customResourceDefinitionActions = ActionsSet.make<DataKeys.CustomResourceDefinitions>({
  uniqueIdentifier: 'id',
  indexBy: 'clusterId',
  entityName: entityNamesByKey[DataKeys.CustomResourceDefinitions],
  cacheKey: DataKeys.CustomResourceDefinitions,
})

export const listCustomResourceDefinitions = customResourceDefinitionActions.add(
  new ListAction<DataKeys.CustomResourceDefinitions, { clusterId: string }>(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Custom Resource Definitions', params)
    const { clusterId } = params
    return qbert.getCustomResourceDefinitions(clusterId)
  }),
)

export const updateCustomResourceDefinition = customResourceDefinitionActions.add(
  new UpdateAction<
    DataKeys.CustomResourceDefinitions,
    {
      clusterId: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update Custom Resource Definition', {
      clusterId,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch'
        ? qbert.patchCustomResourceDefinition
        : qbert.updateCustomResourceDefinition
    const updateCustomResourceDefinition = await updateFn({
      clusterId,
      name,
      body,
      contentType,
    })
    trackEvent('Update Custom Resource Definition', { clusterId, name })
    return updateCustomResourceDefinition
  }),
)

export const createCustomResourceDefinition = customResourceDefinitionActions.add(
  new CreateAction<DataKeys.CustomResourceDefinitions, { clusterId: string; body: any }>(
    async ({ clusterId, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new Custom Resource Definition', {
        clusterId,
        body,
      })
      const createCustomResourceDefinition = await qbert.createCustomResourceDefinition(
        clusterId,
        body,
      )
      trackEvent('Create New Custom Resource Definition', {
        clusterId,
        name: pathStr('metadata.name', createCustomResourceDefinition),
      })
      return createCustomResourceDefinition
    },
  ),
)

export const deleteCustomResourceDefinition = customResourceDefinitionActions.add(
  new DeleteAction<DataKeys.CustomResourceDefinitions, { clusterId: string; name: string }>(
    async ({ clusterId, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Custom Resource Definition', {
        clusterId,
        name,
      })
      await qbert.deleteCustomResourceDefinition(clusterId, name)
    },
  ),
)

// Custom Resources
const customResourcesActions = ActionsSet.make<DataKeys.CustomResources>({
  uniqueIdentifier: 'id',
  indexBy: ['clusterId', 'group', 'version', 'pluralName'],
  entityName: entityNamesByKey[DataKeys.CustomResources],
  cacheKey: DataKeys.CustomResources,
})

export const listCustomResources = customResourcesActions.add(
  new ListAction<
    DataKeys.CustomResources,
    { clusterId: string; group: string; version: string; pluralName: string }
  >(async (params) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Custom Resources', params)
    const { clusterId, group, version, pluralName } = params
    return qbert.getCustomResources(clusterId, group, version, pluralName)
  }),
)

export const updateCustomResource = customResourcesActions.add(
  new UpdateAction<
    DataKeys.CustomResources,
    {
      clusterId: string
      group: string
      version: string
      pluralName: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(
    async ({
      clusterId,
      group,
      version,
      pluralName,
      namespace,
      name,
      body,
      requestType = 'put',
      contentType,
    }) => {
      Bugsnag.leaveBreadcrumb('Attempting to update Custom Resource', {
        clusterId,
        namespace,
        name,
        body,
      })
      const updateFn =
        requestType === 'patch' ? qbert.patchCustomResource : qbert.updateCustomResource
      const updatedCustomResource = await updateFn({
        clusterId,
        group,
        version,
        pluralName,
        namespace,
        name,
        body,
        contentType,
      })
      trackEvent('Update Custom Resource', {
        clusterId,
        group,
        version,
        pluralName,
        namespace,
        name,
      })
      return updatedCustomResource
    },
  ),
)

export const deleteCustomResource = customResourcesActions.add(
  new DeleteAction<
    DataKeys.CustomResources,
    {
      clusterId: string
      group: string
      version: string
      pluralName: string
      namespace: string
      name: string
    }
  >(async ({ clusterId, group, version, pluralName, namespace, name }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete Custom Resource', {
      clusterId,
      name,
    })
    await qbert.deleteCustomResource({ clusterId, group, version, pluralName, namespace, name })
  }),
)

export const createCustomResource = customResourcesActions.add(
  new CreateAction<
    DataKeys.CustomResources,
    {
      clusterId: string
      group: string
      version: string
      pluralName: string
      namespace: string
      body: any
    }
  >(async ({ clusterId, group, version, pluralName, namespace, body }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new Custom Resource', {
      clusterId,
      group,
      version,
      pluralName,
      namespace,
      body,
    })
    const createCustomResource = await qbert.createCustomResource({
      clusterId,
      group,
      version,
      pluralName,
      namespace,
      body,
    })
    trackEvent('Create New Custom Resource', {
      clusterId,
      name: pathStr('metadata.name', createCustomResource),
    })
    return createCustomResource
  }),
)
