import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { pick } from 'ramda'
import useReactRouter from 'use-react-router'

import ListContainer from 'core/containers/ListContainer'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'

import useListAction from 'core/hooks/useListAction'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import InferActionParams from 'core/actions/InferActionParams'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import ClusterNameCell from 'k8s/components/common/cells/ClusterNameCell'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { DateAndTime } from 'core/components/listTable/cells/DateCell'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'

import {
  ICustomResourceDetailsPageTabs,
  ICustomResourceDefinitionSelector,
  ICustomResourcesSelector,
} from '../../model'
import { customResourceDefinitionsSelector, customResourcesSelector } from '../../selectors'
import { listCustomResourceDefinitions, listCustomResources } from '../../actions'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { routes } from 'core/utils/routes'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import DeleteCustomResourceDialog from './DeleteCustomResourceDialog'
import { GridDropdownBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import AddCustomResourceModal from '../AddCustomResourceModal'
import DocumentMeta from 'core/components/DocumentMeta'
import Progress from 'core/components/progress/Progress'

const searchTargets = ['name', 'clusterName']
type ModelDataKey = DataKeys.CustomResources
type SelectorModel = ArrayElement<ReturnType<typeof customResourcesSelector>>
type ActionParams = InferActionParams<typeof listCustomResources>

const usePrefParams = createUsePrefParamsHook<ActionParams & TablePrefsParams>(
  'CustomResources',
  listTablePrefs,
)

const defaultParams: ActionParams & SortingState = {
  clusterId: '',
  group: '',
  version: '',
  pluralName: '',
  orderBy: 'name',
  orderDirection: 'asc',
}

export const getCustomResourceDefinitionsColumns = (id): GridViewColumn<SelectorModel>[] => {
  return [
    {
      key: 'name',
      label: 'Name',
      CellComponent: createGridLinkCell({
        routeToFn: ({ clusterId, name }) =>
          routes.clusterAdministration.customResourceDefinitions.customResourceDetails.path({
            clusterId,
            id,
            name,
            tab: ICustomResourceDetailsPageTabs.Overview,
          }),
      }),
    },
    {
      key: 'clusterName',
      label: 'Cluster',
      CellComponent: ClusterNameCell,
    },
    {
      key: 'namespace',
      label: 'Namespace',
      // Return default as namespace if namespace is missing
      formatFn: (_v, item) => {
        return item.namespace ?? 'default'
      },
    },
    {
      key: 'kind',
      label: 'Kind',
    },
    {
      key: 'phase',
      label: 'Phase',
    },
    {
      key: 'creationTimestamp',
      label: 'Created',
      CellComponent: DateAndTime,
    },
    {
      key: 'labels',
      label: 'Labels',
      disableSorting: true,
      CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
    },
  ]
}

function CustomResourcesList() {
  const { allParams: params, getParamsUpdater } = useGlobalParams(usePrefParams, defaultParams)
  const { match } = useReactRouter()
  const { id, clusterId } = match.params
  const [selectedCustomResource, setSelectedCustomResource] = useState<SelectorModel>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

  const { loading: loadingCustomResourceDefinitions } = useListAction(
    listCustomResourceDefinitions,
    {
      params: { clusterId },
    },
  )
  const customResourceDefinitions: ICustomResourceDefinitionSelector[] = useSelector(
    customResourceDefinitionsSelector,
  )
  const customResourceDefinition = useMemo(
    () =>
      customResourceDefinitions.find(
        (customResourceDefinition) => customResourceDefinition?.id === id,
      ),
    [id, customResourceDefinitions],
  )

  const {
    group = '',
    latestVersion: version = '',
    spec: { names: { plural: pluralName } } = { names: { plural: '' } },
  } = customResourceDefinition || {}

  const { loading, reload } = useListAction(listCustomResources, {
    params: { clusterId, group, version, pluralName },
  })
  const customResources: ICustomResourcesSelector[] = useSelectorWithParams(
    customResourcesSelector,
    {
      clusterId,
      group,
      version,
      pluralName,
    },
  )
  const columns = getCustomResourceDefinitionsColumns(id)
  const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
    {
      label: 'Delete',
      icon: 'trash-alt',
      handleClick: (customResource) => {
        setSelectedCustomResource(customResource)
        setShowDeleteDialog(true)
      },
      refreshAfterSuccess: true,
    },
  ]

  const batchActions: GridDropdownBatchActionSpec<SelectorModel>[] = useMemo(
    () => [
      {
        label: 'Delete',
        BatchActionButton: getGridDialogButton(DeleteCustomResourceDialog, {
          action: 'delete',
        }),
      },
    ],
    [],
  )

  return (
    <Progress loading={loading || loadingCustomResourceDefinitions}>
      <DocumentMeta title="Custom Resource" />
      <AddCustomResourceModal
        addRoute={routes.clusterAdministration.customResourceDefinitions.customResourceAdd}
        customResourceDefinition={customResourceDefinition}
      />
      {showDeleteDialog && (
        <DeleteCustomResourceDialog
          onClose={() => setShowDeleteDialog(false)}
          rows={[selectedCustomResource]}
        />
      )}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.CustomResources}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading || loadingCustomResourceDefinitions}
        loadingMessage={'Loading Custom Resources'}
        onRefresh={reload}
        data={customResources}
        columns={columns}
        addText="Add Custom Resource"
        addUrl={routes.clusterAdministration.customResourceDefinitions.customResourceAdd.path({
          id,
          clusterId,
        })}
        rowMenuItems={rowMenuItems}
        getParamsUpdater={getParamsUpdater}
        showItemsCountInLabel
        showRowMenuForSingleRowActions
        disableColumnOrdering
        showBreadcrumbs={false}
        multiSelection
        batchActions={batchActions}
        {...pick(listTablePrefs, params)}
      />
    </Progress>
  )
}

export default CustomResourcesList
